import React from 'react'
import { Box } from 'reflexbox'
import SbEditable from 'storyblok-react'
import { format } from 'date-fns'

import Components from 'src/storyblok/Components'
import Markdown from 'src/components/Markdown/Markdown'
import SEO from 'src/components/SEO/SEO'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'

import styles from './404.module.scss'
import { Link } from 'gatsby'

const NotFoundPage = () => {
  return (
    <Container className={styles.wrapper}>
      <SEO
        title={'404'}
        // metaDescription={seo_description}
        // image={seo_image}
        bodyClass="dark-header"
      />
      <Box
        paddingTop={[15, null, null, 20]}
        paddingBottom={[15, null, null, 15]}
      >
        <Row marginBottom={[2, null, null, 10]}>
          <Column width={1}>
            <Box className={styles.title} as="h1">
              404 - Sidan kunde inte hittas
            </Box>
          </Column>
        </Row>
        <Row marginTop={[10, null, null, 0]}>
          <Column
            width={[1, null, null, 4 / 12]}
            // marginBottom={[10, null, null, 0]}
          ></Column>
          <Column width={[1, null, null, 8 / 12]}>
            <Box className={styles.preamble}>
              <Box as="p">
                Tyvärr kunde vi inte hitta sidan du försökte nu. Försök igen
                eller kontakta oss om det är något du saknar.
              </Box>
            </Box>
            <Row marginTop={[15, null, null, 18]}>
              <Column width={[1, null, null, 1 / 2]}>
                <Link to="/" className={styles.button}>
                  <span>Till startsidan</span>
                </Link>
              </Column>
            </Row>
          </Column>
        </Row>
      </Box>
    </Container>
  )
}

export default NotFoundPage
